import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Paper } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import './styles.css'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TagCard(props: any) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (


    <Card className="card2" /* sx={{ maxWidth: 345, width: 200 }} */ style={{ borderRadius: 20 }}>
      <div style={{ position: "relative" }} onClick={async event => {
        navigate(`/item/${props.item.id}`);
      }}>
        <CardMedia
          component="img"
          style={{ borderRadius: 20 }}
          image={props.item.imageUrl}
          /*  height="140" */
          className="card1"
        />
      </div>

      <CardContent style={{ paddingBottom: 5, paddingTop: 5, height:'50%', /* display:'flex', flexWrap:'wrap', alignContent:'stretch', flexDirection:'column' */ }} >
        <div  className="card_content_full" >
          <Typography className="card_name" style={{ fontWeight: 700 }}>

            {props.item.name}
          </Typography>
        </div>
        <div className="card_content"  >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/*     <div style={{ paddingRight: 5 }}>
              <QueryBuilderRoundedIcon />
            </div>

            <Typography >{props.item?.store?.startTime}:00 - {props.item?.store?.stopTime}:00</Typography> */}
          </div>
          {/*     <Typography style={{ color: "#FAA241", fontWeight: 700 }}>от {props.item.store?.minSum} ₽</Typography> */}
          {
            props.item?.store != null
              ?
              <div  style={{ display: 'flex', justifyContent: 'space-between', color: "#FAA241", fontFamily: 'Nunito-Extra-Bold', height:'90%', alignItems:'flex-end' }}>
                 <QueryBuilderIcon style={{  fontSize: 20, marginRight:5  }}></QueryBuilderIcon>
                <Typography style={{ color: "#FAA241", fontWeight: 700 , fontSize: '0.83rem'}} >{props.item?.store?.startTime}:00 - {props.item?.store?.stopTime}:00</Typography>
                {/* <Typography style={{ color: "#FAA241", fontWeight: 700 }} >до {props.item?.store?.stopTime}:00</Typography> */}
              </div>
              : <div></div>
          }

        </div>
      </CardContent>
    </Card>


    /* <Box sx={{ display: 'flex', alignItems: 'center', margin: 10 }}>
      {props.item.name}
    </Box> */



    /*   </Card> */
  );
}
