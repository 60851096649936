import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Badge, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import { addItem, addItemWithOption, increaseCost, reduceCost, retrieveData } from '../../services/storageService';
import React from 'react';
import { Breadcrumbs, CircularProgress, IconButton, Link, Typography } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BasketButton from "../../Components/Purchase/BasketButton";
import './styles.css'
import Constructor from './Constructor';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootq: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            /* display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between' */
        },
        purchase_button: {
            alignSelf: 'flex-end',
            position: 'fixed',
            bottom: 450
        },
        mainConatinerStyle: {
            flexDirection: 'column',
            flex: 1
        }
    }),
);



export default function ItemDetail(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();
    const [flag, setfFlag] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [tagged, setTagged] = useState<any[]>([]);
    const [product, setProduct] = React.useState<any>({
        count: 0,
        cost: 0,
        delivery_cost: 0
    });

    useEffect(() => {
        fetch(process.env.REACT_APP_TITLE + `/product/detail?id=${params.id}`)
            .then(res => res.json())
            .then(
                (result: any) => {
                    setProduct(result)
                    setIsLoad(false)
                    //setSelectedTag(id)
                    //setItems(result)


                }
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.

            )

    }, []);

    var onPlusButtonPress = (id: number, info: string) => {
        increaseCost(id, info).then(() => {
            refresh()
        })
    }

    var onMinusButtonPress = (id: number, info: string) => {
        reduceCost(id, info).then(() => {
            refresh()
        })
    }


    var refresh = () => {
        // setIsLoaded(true)
        retrieveData().then((data: any) => {
             
            setfFlag(!flag);
            setTagged(data);
            // setIsLoaded(false)
        })
    }

    var addToBasket = async (value: any, sub_id: any, parent_id: any) => {
         
        if (value)
            var v = value
        v.sum = additionalSumm;
        v.info = additionalInfo;


        if (value.options == null) {
            //.navigate(`/detail/${value.id}`)
            addItem(v).then((value: any) => {
                refresh();
                // (props as any).setTextHolder(value)
            })
        }
        else {
            v.options = []
            addItemWithOption(v).then((value: any) => {
                navigate(`/item/${parent_id}/sub/${sub_id}`)
                // (props as any).setTextHolder(value)
            })
        }

    }
    const constructor1 = React.useRef<any>(null);
    const [additionalSumm, setAdditionalSumm] = React.useState(0);
    const [additionalInfo, setAdditionalInfo] = React.useState("");
    const addSum = (sum: any, additionalInfo: any) => {

        setAdditionalSumm(sum);
        setAdditionalInfo(additionalInfo)
    };

    useEffect(() => {
        /*  setIsLoaded(true) */
        retrieveData().then((data: any) => {

            setTagged(data);
            /*  setIsLoaded(false) */
        })

    }, []);

    return (
        <div>
            {
                isLoad
                    ?
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
                    : <div>
                        {
                            (product != undefined) ?


                                <div>

                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/">
                                            Главная
                                        </Link>
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            href={`/item/${product.parent}/sub/${product.tagId}`}
                                        >
                                            {product.tagName}
                                        </Link>
                                        <Typography >{product.name}</Typography>
                                    </Breadcrumbs>

                                    <Typography variant="h4">{product.name}</Typography>
                                    <div style={{ display: 'flex', width: '100%', alignItems:'flex-start' }}>
                                        <div style={{ display: 'flex', width: '50%' }}>
                                            <img src={product.imageUrl} className="card_image_detail"
                                                onError={(e: any) => {
                                                    e.onerror = null;
                                                    e.target.src = "https://avoska-dostavka.ru/avoska_images/A/default.png"
                                                }}

                                            />
                                        </div>

                                        <div style={{ display: 'flex', width: '50%' }}>

                                            {((tagged as any).data?.find((item: any) => item.obj.id == product.id)) != null && product.options == null ?


                                                <div className={classes.card_content}>

                                                    {
                                                        <div>
                                                            {
                                                                product.newPrice == null
                                                                    ?
                                                                    additionalSumm == 0 ?
                                                                        <div className="block__old_price">{product.price} ₽ </div>
                                                                        : <div className="block__old_price">{product.price} ₽ + {additionalSumm} ₽ </div>


                                                                    /*   <div className="block__old_price">{product.price} ₽</div> */

                                                                    :
                                                                    <div className="price_block_for_sale">
                                                                        <div className="sale_block__new_price">{product.newPrice} ₽</div>
                                                                        <div className="sale_block__old_price">{product.price} ₽</div>

                                                                    </div>
                                                            }
                                                        </div>
                                                    }
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => onMinusButtonPress(product.id, product.info)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>

                                                        <Typography variant="body2" component="h2">
                                                            {(tagged as any).data?.find((item: any) => item.obj.id == product.id).count}
                                                        </Typography>



                                                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => onPlusButtonPress(product.id, product.info)}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                                :
                                                <div className={classes.card_content}>

                                                    <div>
                                                        {
                                                            product.newPrice == null
                                                                ?
                                                                additionalSumm == 0 ?
                                                                    <div className="block__old_price">{product.price} ₽ </div>
                                                                    : <div className="block__old_price">{product.price} ₽ + {additionalSumm} ₽ </div>
                                                                :
                                                                <div className="price_block_for_sale">
                                                                    <div className="sale_block__new_price">{product.newPrice} ₽</div>
                                                                    <div className="sale_block__old_price">{product.price} ₽</div>

                                                                </div>
                                                        }
                                                    </div>
                                                    {/* <Typography variant="h5" gutterBottom component="div">
                                            {product.price} ₽
                                        </Typography> */}

                                                    {/*         <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => addToBasket(product)}>
                                        <AddCircleIcon />
                                    </IconButton>
 */}

                                                    <Button variant="contained" endIcon={<AddCircleIcon />} onClick={() => addToBasket(product, product.tagId, product.parent)}>
                                                        В корзину
                                                    </Button>
                                                    <div style={{paddingTop:15}}>
                                                        {product.description != null ?
                                                            <Typography variant="subtitle2" gutterBottom>
                                                                {product.description.split("\n").map((i: string, key: number) => {
                                                                    return <div key={key}>{i}</div>;
                                                                })}

                                                            </Typography>


                                                            : <div></div>
                                                        }

                                                    </div>

                                                   
                                                </div>
                                            }
                                        </div>

                                    </div>

                                    <div>
                                        <BasketButton flag={flag}></BasketButton>
                                    </div>

                                    {
                                                        product.options != null
                                                            ? <Constructor ref={constructor1} data={product.options} addSum={addSum}> </Constructor>
                                                            : <div></div>
                                                    }
                                </div>
                                :
                                <div>
                                </div>
                        }
                    </div>
            }
        </div>
    );
}