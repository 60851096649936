import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import "./styles.css"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /*   flexGrow: 1,
              position:'absolute',
              right: 10,
              bottom: 10 */

            position: 'fixed',
            bottom: 20,
            right: 20
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function OrderButton(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;

    return (
        <div className={classes.root}>

            <Button 
            disabled={props.disabled}
            variant="contained"  onClick={() => { props.onClick() }}>
                Заказать
            </Button>

        </div>
    );
}