
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Badge, Button, debounce, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCallback, useEffect, useRef, useState } from 'react';
import { retrieveData } from '../../services/storageService';
import React from 'react';
import { YMaps, Map, Placemark, withYMaps } from "react-yandex-maps";
import { cn } from '@bem-react/classname';
import "./styles.scss";
import LocationOnIcon from '@mui/icons-material/LocationOn';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flexGrow: 1,
            position: 'absolute',
            right: 20,
            bottom: 20 */
            width: '100%',
            height: '90%'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);


var currntPos = ""

export default function OrderDetail(props: any) {


    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [values, setValues] = React.useState([42.824037, 132.892811]);

    const [isMap, setIsMap] = useState(true);

    const [name, setName] = useState('')

    const getLocation = () => {
        if (!navigator.geolocation) {

        } else {

            navigator.geolocation.getCurrentPosition(async (position) => {





                var a = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=4d526ca4-aed1-48e4-b905-b8e053abe7ce&format=json&geocode=${position.coords.longitude},${position.coords.latitude}`)
                    .then(res => {

                        return res.json()
                    })
                    .then(
                        (result: any) => {
                            currntPos = result.response.GeoObjectCollection.featureMember[0].GeoObject.description + ", " + result.response.GeoObjectCollection.featureMember[0].GeoObject.name

                            setName(result.response.GeoObjectCollection.featureMember[0].GeoObject)

                        }

                    )
            }, () => {

            });
        }
    }
    const css = cn('c-YMap')
    useEffect(() => {

        getLocation();


        //  input = React.createRef();
    }, []);



    const mapRef = useRef<any>();
    const [ymaps, setYmaps] = useState<any>(null);


    var ma: any = null;
    var onclick = () => {

        props.setAddressFromMap((name as any).name)
    }

    var onYmapsLoad = (ymaps: any) => {

        // ymaps = ymaps;
        new ma.SuggestView(input.current).events.add(
            "select",
            onSuggestSelect
        );
    };



    const onMapMove = useCallback(
        debounce(async (e: any) => {

            const center = e.originalEvent.map.getCenter()
            /*   if (isCoordsEqual(center, coords)) {
                return
              } */

            setValues(center)

            await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=4d526ca4-aed1-48e4-b905-b8e053abe7ce&format=json&geocode=${center[1]},${center[0]}`)
                .then(res => {

                    return res.json()
                })
                .then(
                    (result: any) => {


                        //currntPos = result.response.GeoObjectCollection.featureMember[0].GeoObject.description + ", " + result.response.GeoObjectCollection.featureMember[0].GeoObject.name
                        setName(result.response.GeoObjectCollection.featureMember[0].GeoObject)
                    }
                    // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                    // чтобы не перехватывать исключения из ошибок в самих компонентах.

                )
        }, 250),
        []
    )

    var onSuggestSelect = async (e: any) => {
         
        const name = e.get("item").value;


        var a = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=4d526ca4-aed1-48e4-b905-b8e053abe7ce&format=json&geocode=${name}`)
            .then(res => {

                return res.json()
            })
            .then(
                (result: any) => {
                     
                    var r = result.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ')

                    setValues([r[1], r[0]])
                }

            )


    };



    const handleChangeMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    var input = useRef()/* React.createRef<HTMLInputElement>(); */
    return (
        <div className={classes.root}>
            <div style={{ height: '100%' }}>
                <YMaps style={{ with: '100%', height:'100%', margin: 0 }} query={{ load: "package.full" }}>
                    <div style={{ height: '100%' }}>

                        <div style={{}}>
                            <TextField inputRef={input} id="suggest" label="" value={(name as any).name} onChange={handleChangeMobile} variant="outlined" style={{ width: '100%' }} />
                        </div>

                        <Map

                            state={{ center: values, zoom: 16, controls: [] }}
                            width={'100%'}
                            height={'90%'}
                            instanceRef={(ref) => {
                                if (ref) {

                                    mapRef.current = ref;

                                }
                            }}


                            onLoad={(ymaps) => {
                                ma = ymaps
                                setYmaps(ymaps);
                                onYmapsLoad(ymaps)

                            }}


                            onBoundschange={onMapMove}

                        >

                            <i className={css('Point') + ' ' + css('PointItem') + ' icon ion-md-pin'} >
                                <LocationOnIcon fontSize={'large'} style={{ color: '#FAA241' }} />
                            </i>

                        </Map>
                    </div>
                </YMaps>
            </div>
            <Button style={{ marginTop: 5, width: '100%' }} variant="contained" onClick={onclick}>Доставить сюда</Button>

        </div>
    );
}
