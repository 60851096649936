import { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Tag } from '../../model/Tag';
import { Card, CardActionArea, CardContent, CardMedia, Chip, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Item } from '../../model/Item';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { addItem, increaseCost, reduceCost, retrieveData } from '../../services/storageService'
import Slider2 from '../../Core/Slider2'
import CircularProgress from '@mui/material/CircularProgress';
import './styles.css'
import PriceField from './PriceField'
import { Refresh } from '@mui/icons-material';
import Test from './Test'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootq: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',


        },
        purchase_button: {
            alignSelf: 'flex-end',
            position: 'fixed',
            bottom: 450
        },
        mainConatinerStyle: {
            flexDirection: 'column',
            flex: 1
        }
    }),
);


export default function ItemList(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    var tags = (props as any).tags;

    var items = (props as any).items;
    var t: JSX.Element[] = [];
    const [tagged, setTagged] = useState<any[]>([]);
    const [tagId, setTagId] = useState<number>(tags[0] != null ? tags[0].id : 0);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    var onChipClicked = (id: number) => {

        navigate(`/item/${params.id}/sub/${id}`)
        setIsLoaded(true)
        setTagId(id)
        //props.handleClick(id, params.id)
        //setSelectedTag(id)
    }


    useEffect(() => {
        setIsLoaded(true)
        retrieveData().then((data: any) => {

            setTagged(data);
            setIsLoaded(false)
        })

    }, [props.items]);

    var refresh = () => {
         
       // setIsLoaded(true)
        retrieveData().then((data: any) => {
            props.refresh();
            setTagged(data);
            setIsLoaded(false)
        })
    }

    /* var onPlusButtonPress = (id: number) => {
        increaseCost(id).then(() => {
            refresh()
        })
    } */

  /*   var onMinusButtonPress = (id: number) => {
        reduceCost(id).then(() => {
            refresh()
        })
    } */

    var navigateToDeatail = (value: any) => {

        navigate(`/detail/${value.id}`)
        /*   addItem(value).then((value: any) => {
                refresh();
                // (props as any).setTextHolder(value)
            }) */

    }



    var addToBasket = async (value: any) => {


        addItem(value).then((value: any) => {
            refresh();
            // (props as any).setTextHolder(value)
        })

    }

    return (


        <div className={classes.mainConatinerStyle}>


            <div>
                <div>

                    {tags.map(function (x: Tag, i: any) {
                        if (props.selecteTag == x.id) {
                            t.push(<Chip label={x.name} variant="outlined" onClick={() => { onChipClicked(x.id) }} style={{ backgroundColor: '#9266CC', color: 'white', marginRight:10 }} />)

                        }
                        else {
                            t.push(<Chip label={x.name} variant="outlined" onClick={() => { onChipClicked(x.id) }} style={{ backgroundColor: 'white', color: '#9266CC', marginRight:10 }} />)
                        }
                    })}


                </div>

                <Slider2 items={t} isPadding={false}
                ></Slider2>
                {
                    !isLoaded ?




                        <Test tagged={tagged} data={items} id={tagId} refresh={()=>refresh()}></Test>

                        /*  items.map(function (x: Item, i: any) {
                             return <Grid item xs={4}>
                                



                             </Grid>;



                         }) */

                        :

                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                            <CircularProgress color="secondary" />
                        </div>

                    /* < Grid container spacing={2} >
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9].map(function (x: any, i: any) {
                                return <Grid item xs={4}>
                                    <Skeleton variant="rectangular" width={'100%'} height={'110px'} />
                                </Grid>;
                            })
                        }
                    </Grid> */
                }
            </div>
            {/*   :
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
            } */}
        </div >
    );
}