import * as React from 'react';
import './App.css';
import { BottomNavigation, Container, CssBaseline, Switch, Typography } from '@material-ui/core';
import ButtonAppBar from './Core/Navigation'
import AutoGrid from './Components/Products/ProductList'
import TagListContainer from './Containers/TagListCintainer';
import ItemListContainer from './Containers/ItemListContainer';
import BasketList from './Components/Purchase/BasketList';
import PurchaseForm from './Components/Purchase/PurchaseForm';
import Success from './Components/Purchase/Success'
import Login from './Components/Auth/Login'
import UserCard from './Components/Auth/UserCard'
import EditUserCard from './Components/Auth/EditUserCard'
import { StyledEngineProvider } from '@mui/material/styles';
import AppBarBottom from './Core/AppBarBottom'
import History from './Components/Auth/History'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import About from './Components/Info/About'
import Contacts from './Components/Info/Contacts'
import SaleContainer from './Components/Sale/SaleContainer'
import OrderDetail from './Components/Order/OrderDetail'

import "@fontsource/nunito-sans"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,

  useRoutes,
  useNavigate
} from "react-router-dom";
import ItemDetail from './Components/Items/ItemDetail';
import { GlobalStyles } from '@mui/material';
import MenuContainer from './Components/Main/MenuContainer';
import RestListContainer from './Containers/RestListContainer';
import { getUser } from './services/userStorage';
import { User } from './model/User';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Nunito-Extra-Bold',
      'Nunito Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

  },
  palette: {

    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#FAA241',
      contrastText: '#FAA241',
      dark: '#FAA241',

      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },

    secondary: {
      // This is green.A700 as hex.
      main: '#9266CC',
    },
  }

})


function AppRoutes(props: any) {
  const navigate = useNavigate();
  debugger
  React.useEffect(() => {
    // Обновляем заголовок документа с помощью API браузера
    getUser().then((user: User) => {
      debugger
      if (user != null) {

        setValues(user)

      }
      else {
        navigate("/login/tags")
        // setAuthMode(false)
      }
    })
  }, []);
  const routes = useRoutes(
    [
      /*    { path: '/', element: <TagListContainer /> }, */
      { path: '/', element: <RestListContainer /> },
      {
        path: "/item/:id", element: <ItemListContainer />,
        children: [
          { path: "sub/:sub_id", element: <ItemListContainer />, }
        ]
      },
      { path: "/tags", element: <TagListContainer /> },
      { path: "/rests", element: <RestListContainer /> },
      { path: "/basket", element: <BasketList /> },
      { path: "/detail/:id", element: <ItemDetail /> },
      { path: "/purchase", element: <PurchaseForm /> },
      { path: "/success", element: <Success /> },
      { path: "/login/:link", element: <Login /> },
      { path: "/user", element: <UserCard /> },
      { path: "/user_edit", element: <EditUserCard /> },
      { path: "/history", element: <History /> },
      { path: "/about", element: <About /> },
      { path: "/contacts", element: <Contacts /> },
      { path: "/sale", element: <SaleContainer /> },
      {
        path: "/order", element: <OrderDetail />,
        children: [
          { path: "detail", element: <OrderDetail />, }
        ]
      }, {
        path: "/order", element: <OrderDetail />,
        children: [
          { path: "detail", element: <OrderDetail />, }
        ]
      },

    ]
  )
  return routes;
}

function App() {

  return (


    <React.Fragment>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: "white" },
        }}
      />
      <ThemeProvider theme={theme}>

        <Router  >

          <ButtonAppBar />
          <Container maxWidth="lg" >

            <AppRoutes />
            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                {/*   <AppBarBottom></AppBarBottom> */}
              </StyledEngineProvider>
            </React.StrictMode>
          </Container>
        </Router>


      </ThemeProvider>
      {/*  <TagListContainer /> 
      <AutoGrid/> 
      <Typography component="div" style={{ backgroundColor: '#cfe8fc'}} >  */}

    </React.Fragment>

  );
}

export default App;
function setValues(user: User) {
  throw new Error('Function not implemented.');
}

