import React, { useEffect, useState } from "react";
import AutoGrid from "../Components/Products/ProductList";
import RestaurantList from "../Components/Products/RestaurantList";
import { Tag } from "../model/Tag";
import { Parameters } from "../model/Parameters";
import SearchItems from "../Core/Search/SearchItems"
import { Button, CircularProgress, Typography } from "@material-ui/core";
import BasketButton from "../Components/Purchase/BasketButton";
import SearchResult from "../Core/Search/SearchResult"
import SaleBlock from "../Components/Sale/SaleBlock"
import CurrentStatus from "./CurrentStatus";
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import RetunToBlock from "../Components/Sale/RetunToBlock";
import { getUser } from "../services/userStorage";
import { User } from "../model/User";
import { useNavigate } from "react-router-dom";
export default function TagListContainer(props: any) {

  const [tags, setTags] = useState<Tag[]>([]);
  const [stores, setStores] = useState<any[]>([]);

  const [items, setItems] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const [loadProducts, setLoadProducts] = useState<boolean>(true);
  const [loadRest, setLoadRest] = useState<boolean>(true);

  const [loadSearch, setLoadSearch] = useState<boolean>(true);
  const [flag, setfFlag] = useState<boolean>(false);

  const [parameters, setParameters] = useState<Parameters[]>([]);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();


  useEffect(() => {
    // Обновляем заголовок документа с помощью API браузера
    getUser().then((user: User) => {
      debugger
      if (user != null) {

        setValues(user)

      }
      else {
        navigate("/login/tags")
        // setAuthMode(false)
      }
    })

    fetch(process.env.REACT_APP_TITLE + "/tag")
      .then(res => res.json())
      .then(
        (result: Tag[]) => {

          setLoadProducts(false)
          setTags(result)

        }

      )

    fetch(process.env.REACT_APP_TITLE + "/parameters")
      .then(res => res.json())
      .then(
        (result: Parameters[]) => {

          var m = result.find(x => x.key == "STATUS_MESSAGE")
          setMessage(m?.value ?? "")
          // setParameters(result)

        }

      )


    fetch(process.env.REACT_APP_TITLE + "/store")
      .then(res => res.json())
      .then(
        (result: any[]) => {

          setLoadRest(false)
          setStores(result)
        }
      )
  }, []);

  var setLoad = () => {
    setLoadSearch(true)
  }

  var refresh = () => {
    setfFlag(!flag);
  }

  var onChange = (searchString: string) => {

    if (searchString != "") {

      fetch(process.env.REACT_APP_TITLE + `/product/search?name=${searchString}`)
        .then(res => res.json())
        .then(
          (result: any[]) => {
            setLoadSearch(false)
            setSearch(searchString)
            setItems(result)
          }
        )
    }
    else {
      setLoadSearch(false)
      setSearch("")

    }
  }

  return (

    <div>
      <div>
        {(message != "")
          ? <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 5 }}><Typography style={{ color: "#9266cc", textAlign: 'center' }}>{message}</Typography></div>
          : <div></div>
        }

        <RetunToBlock></RetunToBlock>

        <div style={{ paddingTop: 5 }}>
          <Typography variant="h5" style={{ fontWeight: 700, fontFamily: 'Nunito-Extra-Bold' }}>Супермаркет 🛒🍫</Typography>
        </div>
        <div style={{ paddingTop: 10, }}>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', color: "#8920A4", fontFamily: 'Nunito-Extra-Bold' }}>
              {/* <QueryBuilderIcon style={{  fontSize: 20  }}></QueryBuilderIcon> */}
              <Typography style={{ color: "#8920A4", fontFamily: 'Nunito-Extra-Bold', fontSize: 14 }}>Минимальный заказ от 300 ₽
              </Typography>
              {/* <Typography style={{ color: "#8920A4", paddingLeft: 10, fontFamily: 'Nunito-Extra-Bold', fontSize: 14  }}>С 9:00 до 22:00
    </Typography> */}
            </div>

            <Typography style={{ color: "#8920A4", fontFamily: 'Nunito-Extra-Bold', fontSize: 14 }}>Доставка 149 ₽</Typography>
          </div>
        </div>

        <div style={{ paddingTop: 1 }}>
          <SearchItems setLoad={() => setLoad()} onChange={(param: string) => onChange(param)}></SearchItems>
        </div>


        <div style={{ marginBottom: 50 }}>
          {loadSearch
            ?

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
              <CircularProgress style={{ color: '#9266cc' }} />
            </div>
            :

            (search == "") ?
              <div>

                {/*  <div style={{ paddingTop: 15 }}>
                  <Typography style={{ fontWeight: 700 }}>Кафе и рестораны</Typography> */}
                {/*   <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 15 }}>
                  <Typography style={{ fontWeight: 700 }}>Кафе и рестораны</Typography>
                  <Typography style={{ color: "#FAA241", fontWeight: 700 }}>Доставка { stores != null && stores.length > 0 && stores[0].store!= null ? stores[0].store.deliveryPrice : 0} ₽</Typography>
                </div> */}
                {/* </div> */}
                {/*   <div style={{ paddingTop: 15 }}>
                  <Typography style={{ fontWeight: 700 }}>Кафе и рестораны</Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ color: "#FAA241", fontWeight: 700 }}>Доставка 99 ₽</Typography>
                    <Typography style={{ color: "#FAA241", fontWeight: 700 }}>Заказ от 100 ₽</Typography>
                  </div>
                </div> */}
                {/*          {
                  loadRest
                    ? <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                      <CircularProgress style={{ color: '#9266cc' }} />
                    </div>
                    :
                    <RestaurantList tags={stores}></RestaurantList>
                } */}
                <div >
                  {/* 
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: 700 }}>Супермаркет</Typography>
                    <Typography style={{ color: "#FAA241", fontWeight: 700 }}> Доставка 49 ₽</Typography>
                  </div> */}


                  {/*   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                     <div>
                      <QueryBuilderRoundedIcon />
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ paddingRight: 5 }}>
                        <QueryBuilderRoundedIcon />
                      </div>

                      <Typography variant="subtitle1">9:00 - 21:00</Typography>
                    </div>


                      <Typography style={{ color: "#FAA241", fontWeight: 700 }}>Доставка 0 ₽</Typography>
                    <Typography style={{ color: "#FAA241", fontWeight: 700 }}>Заказ от 500 ₽</Typography>
                  </div> */}

                </div>


                <div style={{ paddingTop: 5 }}>
                  {
                    loadProducts
                      ? <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress style={{ color: '#9266cc' }} />
                      </div>
                      :
                      <div>
                        {/* <RetunToBlock></RetunToBlock> */}
                        <AutoGrid tags={tags} ></AutoGrid>
                      </div>
                  }
                </div>
              </div>
              :
              <div>
                <SearchResult refresh={() => refresh()} items={items}></SearchResult>
              </div>
          }
        </div>
        <div>
        {/*   <CurrentStatus></CurrentStatus> */}

        </div>

        <div >

         {/*  <BasketButton flag={flag}></BasketButton> */}
        </div>
      </div>
    </div>)

}
function setValues(user: User) {
  throw new Error("Function not implemented.");
}

