import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Button, FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@material-ui/core';
import './search.css';
import { useEffect, useState } from 'react';

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export function useSearchDebounce(delay = 350) {
  const [search, setSearch] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}

export default function SearchItems(props: any) {

  const [query, setQuery] = React.useState("");
  const [displayMessage, setDisplayMessage] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  const [search, setSearch] = useState(null);
  const [searchTerm, setSearchTerm] = useState('')
  

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm)
      props.onChange(searchTerm)
      // Send Axios request here

    }, 500)

    return () =>{ 
      props.setLoad();
      clearTimeout(delayDebounceFn)}
  }, [searchTerm])

  return (
    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
      <FormControl variant="standard" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

        <TextField
          hiddenLabel
          placeholder="Найти в Авоське"
          style={{ width: '100% ', borderColor: '#9D9D9D', borderRadius: 10, backgroundColor: '#F0EFEF', padding: 10 }}
          onChange={(e) => setSearchTerm(e.target.value)}
          id="input-with-icon-adornment"
          InputProps={{
            startAdornment:
              <InputAdornment position="start" style={{ border: 'none' }}>
                <SearchIcon />
              </InputAdornment>
          }}

        />

        {/*    */}
      </FormControl>
    </div>
  );
}