

import { isMobile } from 'react-device-detect';
import React from "react";
import ReactDOM from "react-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import { LeftArrow, RightArrow } from "./arrows";
/* mport { Card } from "./card";
import { Footer } from "./footer";
import { Header } from "./header"; */
import "./globalStyles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import Stack from '@mui/material/Stack';

// NOTE: embrace power of CSS flexbox!
import "./hideScrollbar.css";
import { Card } from "./card";
import { Breadcrumbs, Button, Chip, Typography } from '@material-ui/core';
import { PropaneRounded } from '@mui/icons-material';

// import "./firstItemMargin.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const elemPrefix = "test";
const getId = (index: number) => `${elemPrefix}${index}`;

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: getId(ind) }));

function TimeSlider(props: any) {

    var isValidTime = () =>{
        return   (new Date()).getHours() >= 0 && (new Date()).getHours() <= 9
    }

    const [items] = React.useState(getItems);
    const { disableScroll, enableScroll } = usePreventBodyScroll();
    const [ selectedTime, setSelectedTime ] =React.useState(isValidTime() ? -1 : 7);
    const Arrows = () => (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
            }}
        >
            {
                props.isPadding
                    ? <div style={{ marginLeft: "10px", display: "flex" }}>
                        <LeftArrow /> <RightArrow />
                    </div>
                    : <div></div>
            }


        </div>
    );

   


    return (
        <>

            <div className="example" style={{ marginTop: 10, marginBottom: 10 }}>
                <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>


                    <ScrollMenu
                        // or on top
                        // Header={Arrows}
                        LeftArrow={isMobile ? <div></div> : LeftArrow}
                        RightArrow={isMobile ? <div></div> : RightArrow}
                        //   Footer={isMobile?<div></div> : Arrows}
                        onWheel={onWheel}

                    >

                        {props.items.map((x: any, i: any) => {

                            return (

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: 'white',
                                    padding: 5,
                                    alignItems: 'center',
                                    background: x.id == props.selectedTime ? '#9266cc' : '#9266cc85',
                                    marginRight: 10, width: 150, borderRadius: 5
                                }}

                                    onClick={() => { return props.onchangeTime(x.id) }}>

                                    <div> {x.start != -1 ? x.day + ", " + x.start + " - " + x.end
                                        /* (new Date()).getHours() > 21 && (new Date()).getHours() > 21 */
                                        /*  <div>Как можно скорее </div> */
                                        : <div>
                                            Как можно скорее


                                        </div>
                                    }

                                    </div>

                                    {
                                        !isValidTime() && x.start == -1
                                            ?
                                            <div>Работаем с 9:00</div>
                                            :
                                            <div>{x.price}</div>
                                    }


                                </div>
                            )
                        })}
                        {/*  {items.map(({ id }) => (
              <Card
                title={id}
                itemId={id} // NOTE: itemId is required for track items
                key={id}
              />
            ))} */}
                    </ScrollMenu>
                    {/*  <ScrollMenu

-
                        onWheel={onWheel}
                    >
                        <Stack direction="row" spacing={1} style={{paddingBottom:10}}>


                            {props.items.map((x: any, i: any) => {
                            
                                return (x)
                            })}
                        </Stack>
                    </ScrollMenu> */}
                </div>

            </div>
        </>
    );
}
export default TimeSlider;


function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}
