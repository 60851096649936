/* import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

function Slider2(props: any) {
    const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const isItemSelected = (id: any) => !!selected.find((el) => el === id);

    const handleClick =
        (id: any) =>
            ({ }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    return (
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} >
            {props.items.map((x: any, i: any) => (
                x
            ))}
        </ScrollMenu>
    );
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        <IconButton color="primary" aria-label="upload picture" component="span"
            className="prev"
            disabled={isFirstItemVisible} onClick={() => scrollPrev()}
            style={{ left: 0 }}
        >
            <ArrowBackIosIcon />
        </IconButton>
       
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        <div>
            <IconButton color="primary" aria-label="upload picture" component="span"
                className="next"
                disabled={isLastItemVisible} onClick={() => scrollNext()}
                style={{ right: 0 }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </div>
   
    );
}


export default Slider2; */

import {isMobile} from 'react-device-detect';
import React from "react";
import ReactDOM from "react-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import { LeftArrow, RightArrow } from "./arrows";
/* mport { Card } from "./card";
import { Footer } from "./footer";
import { Header } from "./header"; */
import "./globalStyles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import Stack from '@mui/material/Stack';

// NOTE: embrace power of CSS flexbox!
import "./hideScrollbar.css";
import { Card } from "./card";

// import "./firstItemMargin.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const elemPrefix = "test";
const getId = (index: number) => `${elemPrefix}${index}`;

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: getId(ind) }));

function Slider2(props: any) {
    const [items] = React.useState(getItems);
    const { disableScroll, enableScroll } = usePreventBodyScroll();


    const Arrows = () => (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
            }}
        >
            {
                props.isPadding
                    ? <div style={{ marginLeft: "10px", display: "flex" }}>
                        <LeftArrow /> <RightArrow />
                    </div>
                    : <div></div>
            }


        </div>
    );


    return (
        <>

            <div className="example" style={{ marginTop: 10, marginBottom: 10 }}>
                <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>


                    <ScrollMenu
                        // or on top
                        // Header={Arrows}
                        LeftArrow={isMobile?<div></div> : LeftArrow}
                        RightArrow={isMobile?<div></div> : RightArrow}
                     //   Footer={isMobile?<div></div> : Arrows}
                        onWheel={onWheel}
                        
                    >

                        {props.items.map((x: any, i: any) => {

                            return (x)
                        })}
                        {/*  {items.map(({ id }) => (
              <Card
                title={id}
                itemId={id} // NOTE: itemId is required for track items
                key={id}
              />
            ))} */}
                    </ScrollMenu>
                    {/*  <ScrollMenu

-
                        onWheel={onWheel}
                    >
                        <Stack direction="row" spacing={1} style={{paddingBottom:10}}>


                            {props.items.map((x: any, i: any) => {
                            
                                return (x)
                            })}
                        </Stack>
                    </ScrollMenu> */}
                </div>

            </div>
        </>
    );
}
export default Slider2;


function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}
