import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router';
import Logo from '../Components/svg/Logo';
import LogoIcon from '../Components/svg/LogoIcon';
import { styled, SvgIcon } from '@material-ui/core';
import TitleLogo from '../Components/svg/TitleLogo';
import "./style.css";
const pages = ["Каталог", "О доставке", "Контакты"];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const BootstrapButton = styled(Button)({

  fontSize: 40,
  /* padding: '6px 12px', */
  border: 'none',
 /*  lineHeight: 1.5, */
  boxShadow: 'none',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),

});
const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileMenu = () => {
    navigate("/user")
  };

  const handleHistoryMenu = () => {
    navigate("/history")
  };

  const navigate = useNavigate();


  return (

      
      <AppBar position="static"  elevation={0} className="appbar"> 

<Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0, boxShadow:'none' }} >
  <Toolbar disableGutters style={{boxShadow:'none'}}>
          {/*   <LogoIcon  onClick={()=>navigate("/")}></LogoIcon> */}
          {/*  {/*     <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <div style={{display: 'flex', justifyContent: 'center'}}>
            {/* <img width={'100%'} src={require("../assets/images/group.png")} /> */}
             {/*  <LogoIcon onClick={() => navigate("/")}></LogoIcon>
              <Logo onClick={() => navigate("/")}></Logo> */}
              <TitleLogo onClick={() => navigate("/")}></TitleLogo>
            </div>

          </Typography>

          {/*           <Logo  onClick={()=>navigate("/")}></Logo> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon  className='t'/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            {/*   {pages.map((page) => ( */}
                <MenuItem onClick={() => navigate("/about")}>
                  <Typography textAlign="center">О доставке</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate("/contacts")}>
                  <Typography textAlign="center">Контакты</Typography>
                </MenuItem>
            </Menu>
          </Box>
         {/*  <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none', justifyContent:'center' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
               <TitleLogo onClick={() => navigate("/")}></TitleLogo> 
           {/*  <div style={{display: 'flex', alignItems: 'center'}}> */}
            {/* <img width={'100%'} height={'50%'} src={require("../assets/images/group.png")} />
           */}  {/*   <LogoIcon onClick={() => navigate("/")}></LogoIcon>
              <Logo onClick={() => navigate("/")}></Logo> */}
            {/* </div> */}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           {/*  {pages.map((page) => ( */}
              <Button
                onClick={() => navigate("/about")}
                sx={{ my: 2, color: '#FAA241', display: 'block' }}
              >
               О доставке
              </Button>

              <Button
                onClick={() => navigate("/contacts")}
                sx={{ my: 2, color: '#FAA241', display: 'block' }}
              >
               Контакты
              </Button>
          
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton 
              onClick={handleOpenUserMenu} 
            
            
              
              
              color="inherit"
              >
             
             <AccountCircleIcon className='t' />
             {/*    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
 */}              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

              <MenuItem key={"Profile"} onClick={handleProfileMenu}>
                <Typography textAlign="center">Профиль</Typography>
              </MenuItem>

              <MenuItem key={"History"} onClick={handleHistoryMenu}>
                <Typography textAlign="center">История заказов</Typography>
              </MenuItem>


            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
