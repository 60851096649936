import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { increaseCost, reduceCost, retrieveData } from '../../services/storageService'
import { Alert, AlertTitle, CircularProgress, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PurchaseButton from "../Purchase/PurchaseButton"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import "core-js/actual/array/group-by-to-map";
import { Parameters } from "../../model/Parameters"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingTop: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },

    }),
);



export default function BasketList(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [purchase, setPurchase] = useState<any[]>([]);
    const [isLoad, setIsLoad] = useState(true);
    const [values, setValues] = React.useState<any>({
        count: 0,
        cost: 0,
        newCost: 0,
        delivery_cost: 0
    });
    const [deliveryCost, setDeliveryCost] = useState<number>(0);

    var getDeliveryPrice = (data: any, marketPrice: any) => {
         
        var res = data.find((x: any) => {
            return x.obj.store != null;
        })

        var resShop = data.find((x: any) => {
            return x.obj.store == null;
        })
        /* var res = data.filter((x: any) => {
            return (x.obj.tagId >= 131 && x.obj.tagId <= 138) ||
                (x.obj.tagId >= 152 && x.obj.tagId <= 154) ||
                (x.obj.tagId >= 140 && x.obj.tagId <= 140) ||
                (x.obj.tagId >= 156 && x.obj.tagId <= 158) ||
                (x.obj.tagId >= 171 && x.obj.tagId <= 179)
        }
        ) */
        var sum = 0;
        sum += res == undefined ? 0 : res.obj.store.deliveryPrice

        sum += resShop == null ? 0 : parseInt(marketPrice)

        return sum;

    }
    var refresh = () => {

        retrieveData().then((data: any) => {
            data.data.sort((a: any, b: any) => {

                var aa = a.obj.store != undefined ? a.obj.store.id : 10;
                var bb = b.obj.store != undefined ? b.obj.store.id : 10;

                if (aa < bb) {
                    return -1;
                }
                if (aa > bb) {
                    return 1;
                }
                return 0;
            }
            )
            setPurchase(data);

            setValues({
                count: data.count,
                cost: Math.round(data.cost),
                newCost: Math.round(data.newCost),
                //delivery_cost: getDeliveryPrice(data.data)
            })
            checkSum(data.data)
            setIsLoad(false)

        })
    }
    var add = (accumulator: any, a: any) => {
        return accumulator + a;
    }

    const [shopError, setShopError] = useState<boolean>(false);
    const [restError, setRestError] = useState<boolean>(false);



    const [emptyError, setEmptyError] = useState<boolean>(false);
    const [currentShopSum, setCurrentShopSum] = useState<number>(0);
    const [currentRestSum, setCurrentRestSum] = useState<number>(0);

    var checkSum = (data: any) => {
         


        var rest = data.filter((x: any) => {
            return x.obj.store != null;
        })
        const sumRest = rest.map((x: any) => x.obj.price * x.count).reduce(add, 0)

        var shop = data.filter((x: any) => {
            return x.obj.store == null;
        })
        const sumShop = shop.map((x: any) => (x.obj.newPrice != null ? x.obj.newPrice : x.obj.price) * x.count).reduce(add, 0)

        var r = false
        var shopError = false
        var restError = false
        var emptyError = false
        if (rest.length == 0 && shop.length == 0) {
            r = true
            emptyError = true
        }
        else {
            if (rest.length > 0) {
                if (sumRest < 100) {
                    restError = true
                    r = true
                }
            }
            else {
                if (sumShop < 500) {
                    shopError = true
                    r = true
                }
            }

            if (shop.length > 0) {
                if (sumShop < 500) {
                    shopError = true
                    r = true

                }
            }
        }

        setCurrentShopSum(sumShop)
        setCurrentRestSum(sumRest)
        setIsValidSum(r)
        /*     setIsValidSum(r)
            setShopError(shopError)
            setRestError(restError)
            setEmptyError(emptyError) */
    }
    const [isValidSum, setIsValidSum] = useState<boolean>(true);
    const [tagged, setTagged] = useState<any[]>([]);
    var onPlusButtonPress = (id: number, info: string) => {
        increaseCost(id, info).then(() => {
            refresh()
            // checkSum((purchase as any).data)
        })
    }

    var onMinusButtonPress = (id: number, info: string) => {

        reduceCost(id, info).then(() => {
             
            refresh()
            // checkSum((purchase as any).data)
        })
    }

    const [marketMinPrice, setMarketMinPrice] = useState<number>(0);
    const [marketDeliveryPrice, setMarketDeliveryPrice] = useState<number>(0);

    useEffect(() => {


        retrieveData().then((data: any) => {


            fetch(process.env.REACT_APP_TITLE + "/parameters")
                .then(res => res.json())
                .then(
                    function (result: Parameters[]) {


                        data.data.sort((a: any, b: any) => {

                            var aa = a.obj.store != undefined ? a.obj.store.id : 10;
                            var bb = b.obj.store != undefined ? b.obj.store.id : 10;

                            if (aa < bb) {
                                return -1;
                            }
                            if (aa > bb) {
                                return 1;
                            }
                            return 0;
                        }
                        );
                        //setTagged(data);
                        setPurchase(data);
                        checkSum(data.data);
                        var c = 0;
                        for (var i = 0; i < data.data.length; i++)
                            c += data.data[i].count;


                        setValues({
                            newCost: Math.round(data.newCost),
                            count: c,
                            cost: Math.round(data.cost),
                            // delivery_cost: getDeliveryPrice(data.data)
                        });

                        var m = result.find(x => x.key == "MARKET_DELIVERY_PRICE");
                        setMarketDeliveryPrice((m as any).value);

                        var m_1 = result.find(x => x.key == "MARKET_MIN_PRICE");
                        setMarketMinPrice((m_1 as any).value);

                        setDeliveryCost(getDeliveryPrice(data.data, (m as any).value));
                        checkSum(data.data)
                        setIsLoad(false)
                        // setParameters(result)
                    }

                );



        });

    }, []);

    var path = [
        { name: "Домашняя", href: "/purchase" },
    ]

    var currentName = ""
    return (

        <div className={classes.root}>

            {
                (!isLoad) ?
                    <div style={{ paddingBottom: 30 }}>
                        <div>


                            {
                                (currentRestSum == 0 && currentShopSum == 0)
                                    ?
                                    <div style={{ marginTop: 10 }}>
                                        <Alert severity="error" >
                                            <AlertTitle>Добавьте в корзину что-нибудь</AlertTitle>
                                        </Alert>
                                    </div>
                                    : <Alert severity="info">

                                        <AlertTitle>Корзина</AlertTitle>
                                        <div>Товаров в корзине:  <strong> {values?.count}</strong></div>
                                        <div>на сумму <strong> {values?.newCost} ₽</strong></div>
                                        {
                                            values?.cost != 0 && values?.cost - values?.newCost != 0
                                                ? <div>с учетом скидки <strong> {(values?.cost - values?.newCost)} ₽</strong></div>
                                                : <div></div>
                                        }
                                        <div>+ доставка <strong> {deliveryCost} ₽</strong></div>
                                        {/*  <div>Всего: <strong> {values?.delivery_cost + values?.newCost} ₽</strong></div> */}
                                    </Alert>
                            }

                            {
                                (currentShopSum > 0 && currentShopSum < marketMinPrice)
                                    ?
                                    <div style={{ marginTop: 10 }}>
                                        <Alert severity="error" >
                                            <AlertTitle>До минимальной суммы из супермаркета осталось {marketMinPrice - currentShopSum} ₽</AlertTitle>
                                        </Alert>
                                    </div>
                                    : <div></div>
                            }

                            {
                                (currentRestSum > 0 && currentRestSum < 100)
                                    ? <div style={{ marginTop: 10 }}>
                                        <Alert severity="error">
                                            <AlertTitle>До минимальной суммы из ресторана осталось {100 - currentRestSum} ₽</AlertTitle>
                                        </Alert>
                                    </div>
                                    : <div></div>
                            }
                            <List sx={{ width: '100%', maxWidth: '100%' }}>

                                {(purchase as any)?.data?.map(function (x: any, i: any) {

                                    return <List style={{ maxWidth: '100%', width: '100%' }}>
                                        {

                                            currentName != (x.obj.store != null ? x.obj.store.name : "Супермаркет")
                                                ? <Typography variant="h6" gutterBottom>
                                                    {currentName = x.obj.store != null ? x.obj.store.name : "Супермаркет"}
                                                </Typography>

                                                : <div></div>
                                        }


                                        <ListItem secondaryAction={
                                            /*     <IconButton edge="end" aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton> */

                                            <div className={classes.card_content}>

                                                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => onMinusButtonPress(x.obj.id, x.obj.info)}>
                                                    <RemoveCircleIcon />
                                                </IconButton>

                                                <Typography variant="body2" component="h2">
                                                    {x.count}
                                                </Typography>



                                                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => onPlusButtonPress(x.obj.id, x.obj.info)}>
                                                    <AddCircleIcon />
                                                </IconButton>
                                            </div>
                                        }>

                                            <div>

                                                <img height={100} src={x.obj.imageUrl} />

                                            </div>

                                            <div style={{ width: '80%' }}>
                                                <ListItemText style={{ width: '80%' }} primary={x.obj.name} secondary={
                                                    <div>
                                                        {x.obj.newPrice == null
                                                            ? <div>{x.count} x {x.obj.price} ₽ {x.obj.sum != undefined && x.obj.sum != 0 ? " + " + x.obj.sum + " ₽" : ""}</div>
                                                            : <div style={{ display: 'flex', flexDirection: 'row' }}>{x.count} x {x.obj.newPrice} ₽<div style={{ paddingLeft: 5, textDecoration: 'line-through' }}> {x.obj.price} ₽</div></div>
                                                        }
                                                        <Typography style={{ width: '60%' }} variant="caption" display="block" gutterBottom>
                                                            {x.obj.info}
                                                        </Typography>

                                                    </div>



                                                }
                                                />
                                            </div>


                                        </ListItem>

                                        {/*     <Divider variant="inset" component="li" /> */}
                                    </List>
                                })}

                            </List>
                        </div>
                        <div>
                            <PurchaseButton disabled={isValidSum}></PurchaseButton>

                        </div>
                    </div>
                    : <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
            }
        </div >
    );
}