import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /*   position: 'fixed',
              bottom: 20,
              right: 20 */
              paddingBottom:10
        },
       
    }),
);


export default function RetunToBlock(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    
    var onClick = ()=>{
        navigate("/rests")
    }

    
    return (
        <div className={classes.root}>
            <Button 
             className="return_to_button" 
             onClick={onClick}
             variant="outlined">
                 Кафе и рестораны
            </Button>
           {/*  <img width={'100%'} height={'100%'} src={require("../../assets/images/help.png")} /> */}
        </div>
    );
}