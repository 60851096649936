import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Countdown from "react-countdown";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Alert, Avatar, Button, Card, CardContent, CardHeader, CardMedia, FormControl, IconButton, Input, InputAdornment, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import makeRequest from '../../api/makeRequest';
import { setUser } from '../../services/userStorage';
import { User } from '../../model/User';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography'
import Register from './Register'
import InputMask from "react-input-mask";
import { SmsResponce } from '../../model/SmsResponce';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

            right: 10,
            bottom: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);




interface State {
    mobile: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
    userCode: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Login(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [code, setCode] = useState<string>("");
    const [waiting, setWaithig] = useState<boolean>(false);
    const [codeError, setCodeError] = useState<string>("");
    const [responceError, setResponceError] = useState<string>("");
    const [isButtonAvailable, setIsButtonAvailable] = useState<boolean>(true);
    const [hasNoRight, setHasNoRight] = useState<boolean>(true);

    const [values, setValues] = React.useState<State>({
        mobile: '',
        password: '',
        weight: '',
        weightRange: '',
        userCode: '',
        showPassword: false,
    });


    const [users, setUsers] = React.useState<State>({
        mobile: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        userCode: '',
    });

    const handleChange1 =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {

            setValues({ ...values, [prop]: event.target.value });

            if (prop == "mobile") {
                var m = event.target.value.replace('+', '').replace(/\s+/g, "").replace('(', '').replace(')', '')

                setIsButtonAvailable(m.indexOf('_') != -1 || m == "")
                /*  if (m.indexOf('_') != -1) {
                     setResponceError("Номер указан неверно")
                 } */
            }
        };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    var onClick = () => {

        setResponceError("")
        //var user = { Phone: "+7" + values.mobile, Password: values.password }
        var m = values.mobile.replace('+', '').replace(/\s+/g, "").replace('(', '').replace(')', '')
        debugger

        if (!["79998599597","79841489391", "79253532260"].includes(m)) {
            setResponceError("Нет доступа")
            setHasNoRight(false)
        }
        else {
            if (m.indexOf('_') != -1) {
                setResponceError("Номер указан неверно")
            }
            else {
                fetch(process.env.REACT_APP_TITLE + `/account/code?number=${m}`)
                    .then((response: any) => {


                        if (response.status == 400 || response.status == 401) {
                            return;
                        }

                        return response.json()
                    })
                    .then((res: SmsResponce) => {
                        setCode(res.code)
                        setWaithig(true)
                        setIsButtonAvailable(true)
                        if (res.status == "ERROR") {
                            setResponceError(res.status_text)
                        }


                        /*  if (res != undefined) {
                             setUser(res);
                             
                             navigate("/user")
                             return res;
                         } */
                    })
            }
        }
    }

    var onCheckClick = () => {

        /*   if (code == values.userCode) { */

        var m = values.mobile.replace('+', '').replace(/\s+/g, "").replace('(', '').replace(')', '')

        if (m.indexOf('_') != -1) {
            setCodeError("Номер указан неверно")
        }
        else {
            onClick1(m, values.userCode);
        }
        // navigate("/")
        /*  } */

        /* else {
            setCodeError("Код неверный")
        } */
    }
    var onClick1 = (mobile: any, password: any) => {
        var user = { Phone: mobile, Password: password }

        fetch(process.env.REACT_APP_TITLE + "/account/token",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(user)
            })

            .then((response: any) => {

                if (response.status == 400 || response.status == 401) {
                    setCodeError("Код указае неверно")
                    return;
                }

                return response.json()
            })
            .then((res: User) => {

                if (res != undefined) {
                    setUser(res);
                    /*    (this.props as any).authUser();
   
                       this.setState({
                           isLoading:false
                       }) */

                    navigate("/" + params.link)
                    return res;
                }
            })
    }

    const params = useParams();
    const Completionist = () => <span></span>;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }: any) => {
        if (completed) {
            // Render a complete state

            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span>
                    {seconds}
                </span>
            );
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    var rere = () => {

        setIsButtonAvailable(false)
    }
    const [value, setValue] = React.useState(0);
    return (
        <div className={classes.root}>

            <Box sx={{ width: '100%' }}>
                {/*  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Войти" {...a11yProps(0)} />
                        <Tab label="Зарегестрироваться" {...a11yProps(1)} />

                    </Tabs>
                </Box> */}
                <TabPanel value={value} index={0}>
                    <Typography variant="h4" gutterBottom>
                        Войти
                    </Typography>
                    <div>
                        <FormControl fullWidth variant="standard">
                            {/* <InputLabel htmlFor="standard-adornment-amount">Номер</InputLabel>
 */}

                            <InputMask
                                mask="+7 (999) 999 9999"
                                value={values.mobile}

                                maskChar={"_"}
                                onChange={handleChange1('mobile')}

                            >
                                {() => <TextField
                                    label="Мобильный *"
                                    error={values.mobile == ""}
                                    margin="normal"
                                    type="text"
                                />}
                            </InputMask>
                            {/*  <Typography variant="caption" gutterBottom>
                                Введите код из смс
                            </Typography> */}
                        </FormControl>


                        {
                            !hasNoRight ?
                                <div></div>
                                :
                                <div style={{ width: '100%' }}>
                                    <Button style={{ width: '100%' }} disabled={isButtonAvailable} onClick={() => { onClick() }} variant="contained">Получить СМС с кодом</Button>
                                </div>
                        }

                        {
                            waiting && responceError == ""
                                ? <div style={{ paddingTop: 20 }}>

                                    {
                                        isButtonAvailable
                                            ? <div>Повторить попытку можно через <Countdown date={Date.now() + 25000} renderer={renderer} onComplete={() => { return rere() }} /> c</div>
                                            : <div></div>
                                    }
                                    <TextField
                                        style={{ width: '100%' }}
                                        label="Код из смс"

                                        value={values.userCode}
                                        onChange={handleChange1('userCode')}

                                        autoComplete='oneTimeCode'
                                        margin="normal"
                                        type="text"
                                    />
                                    <div style={{ width: '100%' }}>
                                        <Button style={{ width: '100%' }} disabled={values.userCode == "" || values.userCode.length < 4} onClick={() => { onCheckClick() }} variant="contained">Вперед!</Button>
                                    </div>
                                </div>
                                : <div>
                                    {
                                        responceError != ""
                                            ? <Alert severity="error" style={{ marginTop: 10 }}>{responceError}</Alert>
                                            : <div></div>
                                    }
                                </div>
                        }

                        {
                            codeError != ""
                                ? <Alert severity="error" style={{ marginTop: 10 }}>{codeError}</Alert>

                                : <div></div>
                        }
                        <div>Проблемы со входом?  <a style={{ color: '#FAA241' }} href="tel:+7 (984) 148-93-91"> Позвоните нам</a></div>
                        {/* s */}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div>
                        <Register />
                    </div>
                </TabPanel>

            </Box>



        </div>
    );
}