import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css'
import ButtonBase from '@mui/material/ButtonBase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      /*   position: 'fixed',
        bottom: 20,
        right: 20 */
      paddingBottom: 10
    },
    buttonStep: {
      width: "150px",
      height: "49px",
      backgroundImage: "url(./rest.jpg)"
    }

  }),
);

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));


export default function MarketBlock(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  var tags = (props as any).tags;

  var onClick = () => {
    navigate("/tags")
  }


  return (
    <div className={classes.root}>

      {/* <ImageButton
          focusRipple
        
          style={{
            width: '100%',
          }}
        >
          <ImageSrc style={{ backgroundImage: "./rest.jpg"}} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              Перейти в Супермаркет
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton> */}

      {/* <div className={"yr1"}> */}
      <Button className={"yr"}   onClick={onClick}>Супермаркет
   
      </Button>
      {/*  </div> */}
      {/*  <Button 
             className="market_button" 
             onClick={onClick}
             variant="outlined">
                 Перейти в Супермаркет
            </Button> */}
      {/*  <img width={'100%'} height={'100%'} src={require("../../assets/images/help.png")} /> */}
    </div>
  );
}