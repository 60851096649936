import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Badge, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect } from 'react';
import { retrieveData } from '../../services/storageService';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flexGrow: 1,
            position: 'absolute',
            right: 20,
            bottom: 20 */

            position: 'fixed',
            bottom: 20,
            right: 20
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function BasketButton(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [values, setValues] = React.useState<any>({
        count: 0,
        cost: 0,
        delivery_cost: 0
    });

    useEffect(() => {
        retrieveData().then((value: any) => {

            var c = 0;
            for (var i = 0; i < value.data.length; i++)
                c += value.data[i].count;
            setValues({
                count: c,
                cost: Math.round(value.cost),
                newCost: Math.round(value.newCost),
                delivery_cost: 0
            })
            /* this.setState({
                count: c,
                cost: value.cost,
                deliveryPrice: this.getDeliveryPrice(value.data),
                cost_all: Math.floor(value.cost) + Math.floor(this.getDeliveryPrice(value.data)) ,
            }); */
        })

    }, [props.flag]);


    return (
        <div className={classes.root}>
            <Badge anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }} color="secondary" badgeContent={values.count}>


                <Badge anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }} badgeContent={values.newCost + " ₽"} color="secondary">


                    {/* <IconButton
                    style={{backgroundColor: ''}}
                    color="primary"  ria-label="delete" size="large">
                        <ShoppingCartIcon />
                    </IconButton>
 */}
                {/*     <Button endIcon={<ShoppingCartIcon />} variant="contained" onClick={() => { navigate(`/basket`); }} style={{background:'#FAA241', color: 'white'}}>
                        Корзина
                    </Button> */}

                </Badge>
            </Badge>
        </div>
    );
}