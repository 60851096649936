import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import ItemList from "../Components/Items/ItemList";
import BasketButton from "../Components/Purchase/BasketButton";
import { Item } from "../model/Item";
import { Tag } from "../model/Tag";



function getItemsByTagId(id: number) {
    fetch(process.env.REACT_APP_TITLE + `/product?tagId=${id}&mode=${0}`)
        .then(res => res.json())
        .then(
            (result: Item[]) => {


                // setTags(result)
            }
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.

        )
}

export default function ItemListContainer(props: any) {


    // var tags = (props as any).tags;
    const params = useParams();
    const [tags, setTags] = useState<Tag[]>([]);
    const [items, setItems] = useState<Item[]>([]);
    const [flag, setfFlag] = useState<boolean>(false);
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [selecteTag, setSelectedTag] = useState<number>(-1);
    const navigate = useNavigate();

    var refresh = () => {
        setfFlag(!flag);
    }


    // Аналогично componentDidMount и componentDidUpdate:
    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера
 

        if (params.sub_id == undefined) {
            fetch(process.env.REACT_APP_TITLE + `/tag/children?id=${params.id}`)
                .then(res => res.json())
                .then(
                    (result: Tag[]) => {

                       
                        setTags(result)

                        var id = result[0].id
                        fetch(process.env.REACT_APP_TITLE + `/product?tagId=${id}&mode=${0}`)
                            .then(res => res.json())
                            .then(
                                (result: Item[]) => {

                                    setSelectedTag(id);
                                    setItems(result)
                                    setIsLoad(false)
                                }
                                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                                // чтобы не перехватывать исключения из ошибок в самих компонентах.

                            )
                    }
                    // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                    // чтобы не перехватывать исключения из ошибок в самих компонентах.

                )
        }
        else {
            var a = Number(params.sub_id)
            setIsLoad(false)
            handleClick(a)
        }

    }, [params.id, params.sub_id]);

    var handleClick = (id: number) => {
        fetch(process.env.REACT_APP_TITLE + `/product?tagId=${id}&mode=${0}`)
            .then(res => res.json())
            .then(
                (result: Item[]) => {

                    //setSelectedTag(id)
                    //setItems(result)

                    fetch(process.env.REACT_APP_TITLE + `/tag/children?id=${params.id}`)
                        .then(res => res.json())
                        .then(
                            (result: Tag[]) => {

                                setTags(result)

                                // var id = result[0].id
                                fetch(process.env.REACT_APP_TITLE + `/product?tagId=${id}&mode=${0}`)
                                    .then(res => res.json())
                                    .then(
                                        (result: Item[]) => {

                                            setSelectedTag(id);
                                            setItems(result)
                                            setIsLoad(false)
                                        }
                                        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                                        // чтобы не перехватывать исключения из ошибок в самих компонентах.

                                    )
                            }
                            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                            // чтобы не перехватывать исключения из ошибок в самих компонентах.

                        )

                }
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.

            )
    }

    return (
        <div style={{ paddingBottom: 20 }}>
            {
                isLoad
                    ? <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
                    :

                    <div>
                        <div style={{ paddingBottom: 50 }}>
                            <ItemList tags={tags} items={items} selecteTag={selecteTag} handleClick={handleClick} refresh={refresh}></ItemList>
                        </div>
                        <div>
                            <BasketButton flag={flag}></BasketButton>
                        </div>
                    </div>
            }
        </div>

    );
}